import { useState } from 'react'
import classNames from 'classnames'
import { useFormik } from 'formik'

import FormControl from '@/components/base/FormControl'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'
import { useLocaleStore } from '@/store/locale'
import {
  convertJsontoFormData,
  createInitialValues,
  createYupSchema
} from '@/utils/form'
import { API } from '@/constants'
import { axiosServer as axios } from '@/base/axios'

import { WPCF7Form } from '@/types'

export type NewsletterProps = Partial<{
  form_id: string
  fields: WPCF7Form['form']
}>

const Newsletter = ({ form_id, fields = [] }: NewsletterProps) => {
  const currentLocale = useLocaleStore((state) => state.currentLocale)
  const [statusMessage, setStatusMessage] = useState('')

  async function sendContactForm(values: any) {
    const response = await axios.post(
      `${API.GRAVITY_FORM}/${form_id}/submissions`,
      convertJsontoFormData(values),
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return response
  }

  const formik = useFormik<any>({
    initialValues: createInitialValues(fields) || {},
    validationSchema: createYupSchema(fields, currentLocale),
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      const form_url_id = fields.find((field) => field.label === 'form_url')?.id
      const result = sendContactForm({
        ...values,
        ...(form_url_id
          ? { [`input_${form_url_id}`]: window.location.href }
          : {})
      })
      result
        .then((response) => {
          setStatusMessage(response.data.confirmation_message)
          setStatus(response.data.is_valid ? 'success' : 'error')
        })
        .then(() => {
          setTimeout(() => {
            resetForm()
            setStatusMessage('')
            setStatus('')

            setSubmitting(false)
          }, 2000)
        })
    }
  })

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-2 gap-x-gutter gap-y-lg"
    >
      {fields.map((field, index) => (
        <FormControl
          key={index}
          id={`input_${field.id}`}
          name={`input_${field.id}`}
          type={field.type}
          label={field.type === 'consent' ? field.checkboxLabel : field.label}
          required={field.isRequired}
          placeholder={`${field.placeholder}${field.isRequired ? ' *' : ''}`}
          checked={formik.values[`input_${field.id}`] || false}
          value={formik.values[`input_${field.id}`]}
          disabled={formik.isSubmitting}
          onChange={(e) => {
            formik.handleChange(e)
            if (field.type === 'consent') {
              formik.setFieldValue(
                `input_${field.id}_1`,
                e.target.checked ? '1' : '0',
                false
              )
            }
          }}
          onBlur={formik.handleBlur}
          error={
            formik.errors[`input_${field.id}`] !== undefined &&
            formik.touched[`input_${field.id}`] !== undefined
          }
          rounded={true}
          className={classNames('relative col-span-2 lg:col-span-2')}
        >
          {field.type === 'email' ? (
            <div className="absolute right-0 top-0 flex h-[6rem] w-[6rem] items-center justify-center">
              <button
                type="submit"
                className="flex h-[4.8rem] w-[4.8rem] items-center justify-center rounded-full bg-pink"
              >
                <SVGIcon
                  symbol="newsletter"
                  className="h-[1.3rem] w-[1.5rem] fill-white"
                />
              </button>
            </div>
          ) : null}
        </FormControl>
      ))}

      <div className="col-span-2">
        <Typography
          paragraph
          variant="body"
          className={classNames('text-pink', {
            invisible: statusMessage === '',
            visible: statusMessage !== ''
          })}
        >
          {statusMessage}
        </Typography>
      </div>
    </form>
  )
}

export default Newsletter
