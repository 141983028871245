import { MAX_PIXEL_COUNT } from '@/webgl/constants'

export const getSizeFromMaxPixelCount = (
  originalWidth: number,
  originalHeight: number,
  dpr = 1
) => {
  let width = originalWidth * dpr
  let height = originalHeight * dpr

  if (width * height > MAX_PIXEL_COUNT) {
    const ratio = width / height

    height = Math.sqrt(MAX_PIXEL_COUNT / ratio)
    width = Math.ceil(height * ratio)
    height = Math.ceil(height)
  }

  return {
    width,
    height
  }
}

export const getViewportFromCameraFov = (camera: any, depth = 0) => {
  const vFov = (camera.fov * Math.PI) / 180
  const distance = camera.position.z - depth
  const height = 2 * Math.tan(vFov / 2) * Math.abs(distance)
  const width = height * camera.aspect
  return {
    width,
    height
  }
}
