import { Fragment, ReactElement } from 'react'
import classNames from 'classnames'
import {
  GetStaticPropsContext,
  InferGetStaticPropsType,
  NextPageContext
} from 'next'

import Button from '@/components/base/Button'
import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Image from '@/components/base/Image'
import ImageParallax from '@/components/base/ImageParallax'
import Meta from '@/components/base/Meta'
import Motion from '@/components/base/Motion'
import MotionLanding from '@/components/base/MotionLanding'
import Newsletter from '@/components/base/Newsletter'
import Spacer from '@/components/base/Spacer'
import Typography from '@/components/base/Typography'
import Layout from '@/components/layout/Layout'
import {
  homeLandingRevealAnimation,
  homeSectionRevealAnimation
} from '@/components/sections/animations'
import { useLoaderStore } from '@/store/loader'
import { mapHomepageStaticProps } from '@/utils/pageData'
import { getPromiseFromFetch } from '@/utils/request'
import { WebGLDom } from '@/webgl/dom'

import { WPPageData } from '@/types'

export default function Home(
  pageData: InferGetStaticPropsType<typeof getStaticProps>
) {
  const { landing, newsletter, sections } = pageData.acf
  const setLoaderState = useLoaderStore((state) => state.setState)

  return (
    <>
      <MotionLanding animation={homeLandingRevealAnimation}>
        {({ root, image, shape }) => (
          <section
            ref={root}
            className="min-h-svh-vh relative z-section flex w-full items-center"
          >
            <WebGLDom shapeRef={shape} variant="shape">
              {({ targetRef }) => (
                <span
                  ref={targetRef}
                  className="absolute left-1/2 top-1/2 h-[calc(100vw_-_10px)] max-h-[43rem] w-[calc(100vw_-_10px)] max-w-[43rem] -translate-x-1/2 -translate-y-1/2 rounded-full"
                />
              )}
            </WebGLDom>
            <Container className="relative z-10">
              <Grid className="items-center">
                <div className="col-span-2 mb-[3rem] mt-[8rem] md:order-2 md:col-span-3 md:m-0 lg:col-span-6 lg:p-sm xl:p-2xl">
                  <WebGLDom
                    imageRef={image}
                    variant="image"
                    onLoad={() => {
                      setLoaderState({ pageLoaded: true })
                    }}
                  >
                    {({ targetRef }) => (
                      <div ref={targetRef}>
                        <Image
                          ratio="1:1"
                          size="large"
                          priority={true}
                          source={landing.image}
                          alt={landing.image?.alt}
                          className="rounded-full"
                        />
                      </div>
                    )}
                  </WebGLDom>
                </div>
                <div className="col-span-2 md:order-1 md:col-span-3 lg:col-span-5 lg:col-start-2">
                  <div className="z-10 grid gap-y-lg">
                    {landing?.title ? (
                      <Typography
                        variant="h1"
                        component="h1"
                        className="!text-s-h3 text-blue md:!text-d-h3 xl:!text-d-h1 [&_em]:text-pink"
                        data-motion-title
                      >
                        {landing.title}
                      </Typography>
                    ) : null}

                    {landing?.paragraph ? (
                      <Typography
                        variant="body"
                        className="block text-neutral-20"
                        data-motion-paragraph
                      >
                        {landing.paragraph}
                      </Typography>
                    ) : null}
                  </div>
                </div>
              </Grid>
            </Container>
          </section>
        )}
      </MotionLanding>
      <section className="min-h-svh-vh relative z-section w-full" />

      {sections?.map((section: any, index: number) => (
        <Fragment key={`section-${index}`}>
          <Motion animation={homeSectionRevealAnimation}>
            {(motionRef) => (
              <section
                ref={motionRef}
                className="min-h-svh-vh relative z-section flex w-full items-center"
              >
                <Container>
                  <Grid className="items-center gap-y-sm">
                    {section?.image && section?.image.url ? (
                      <div
                        className={classNames(
                          'col-span-2 md:col-span-3 lg:col-span-4',
                          { 'md:order-2 lg:col-start-9': index === 2 }
                        )}
                      >
                        <ImageParallax
                          className={classNames({
                            'rounded-xs': index === 1 || index === 2,
                            'rounded-full': index === 3
                          })}
                          data-motion-parallax
                        >
                          {(ref) => (
                            <Image
                              ref={ref}
                              ratio="1:1"
                              size="large"
                              loading="lazy"
                              source={section.image}
                              alt={section.image?.alt}
                              className={classNames({
                                'rounded-xs': index === 1 || index === 2,
                                'rounded-full': index === 3
                              })}
                            />
                          )}
                        </ImageParallax>
                      </div>
                    ) : null}

                    <div
                      className={classNames('col-span-2', {
                        'md:col-span-6 lg:col-span-10 lg:col-start-2':
                          index == 0,
                        'md:col-span-3 md:col-start-4 lg:col-span-6 lg:col-start-7':
                          index === 1 || index === 3 || index === 4,
                        'md:order-1 md:col-span-3 lg:col-span-6 lg:col-start-2':
                          index === 2,
                        'md:col-span-4 md:col-start-2 lg:col-span-6 lg:col-start-4':
                          index === 5
                      })}
                    >
                      <div
                        className={classNames('grid gap-x-gutter gap-y-lg', {
                          'grid-cols-2 items-end': index === 0
                        })}
                      >
                        {section?.title ? (
                          <div
                            className={classNames({
                              'col-span-2 md:col-span-1': index === 0
                            })}
                          >
                            <Typography
                              variant="h2"
                              component="h3"
                              className="text-s-h3 text-blue lg:text-d-h3 xl:text-d-h2 [&_em]:text-pink"
                              data-motion-title
                            >
                              {section.title}
                            </Typography>
                          </div>
                        ) : null}

                        {section?.paragraph || section?.button ? (
                          <div
                            className={classNames({
                              'col-span-2 md:col-span-1': index === 0
                            })}
                          >
                            <div className="grid gap-y-lg">
                              {section?.paragraph ? (
                                <Typography
                                  variant="body"
                                  className="block text-neutral-20"
                                  data-motion-paragraph
                                >
                                  {section.paragraph}
                                </Typography>
                              ) : null}

                              {section?.button ? (
                                <div data-motion-button>
                                  <Button
                                    href={section.button?.url}
                                    link
                                    variant="contained"
                                  >
                                    {section.button?.title}
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                </Container>
              </section>
            )}
          </Motion>
          <section className="min-h-svh-vh relative z-section w-full" />
        </Fragment>
      ))}

      <section className="relative z-section h-auto w-full">
        <Container>
          <Grid>
            <div className="col-span-2 md:col-span-4 md:col-start-2 lg:col-span-8 lg:col-start-3">
              <div className="grid gap-y-lg">
                {newsletter?.title ? (
                  <Typography
                    variant="h2"
                    component="h2"
                    className="text-blue [&_em]:text-pink"
                  >
                    {newsletter.title}
                  </Typography>
                ) : null}

                {newsletter?.paragraph ? (
                  <Typography variant="body" className="block text-neutral-20">
                    {newsletter.paragraph}
                  </Typography>
                ) : null}

                {newsletter?.form_id && newsletter?.form ? (
                  <Newsletter
                    form_id={newsletter.form_id}
                    fields={newsletter.form}
                  />
                ) : null}
              </div>
            </div>
          </Grid>
        </Container>

        <Spacer variant="2xl" />
      </section>
    </>
  )
}

Home.getLayout = function getLayout(
  page: ReactElement,
  props: NextPageContext
) {
  return (
    <Layout pageData={props}>
      <Meta pageData={props} />
      {page}
    </Layout>
  )
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  if (locale === 'default') return { notFound: true }

  const { data: pageData } = await getPromiseFromFetch<WPPageData>({
    method: 'get',
    url: `/${locale}`
  })

  return {
    props: {
      ...(await mapHomepageStaticProps(pageData))
    }
  }
}
