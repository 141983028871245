/* eslint-disable no-unused-vars */
import { RefObject, useRef } from 'react'
import { useGSAP } from '@gsap/react'

import { useLoaderStore } from '@/store/loader'
import { isTouch } from '@/utils/device'
import { WebGLDomPlaneHandler } from '@/webgl/dom/WebGLDomPlane'
import { useWebGLStore } from '@/webgl/store/loader'

import { useLenis } from '@/contexts/lenis'
import { LandingAnimation } from '@/types'

type MotionLandingProps = {
  children: (refs: {
    root: RefObject<HTMLDivElement>
    image: RefObject<WebGLDomPlaneHandler>
    shape: RefObject<WebGLDomPlaneHandler>
  }) => React.ReactNode
  animation: (props: LandingAnimation) => (() => void) | void // gsap.core.Timeline
  revertOnUpdate?: boolean
}

const MotionLanding = ({
  children,
  animation,
  revertOnUpdate = true
}: MotionLandingProps) => {
  const root = useRef<HTMLDivElement>(null!)
  const image = useRef<WebGLDomPlaneHandler>(null!)
  const shape = useRef<WebGLDomPlaneHandler>(null!)
  const loaded = useLoaderStore((state) => state.pageLoaded)
  const compiled = useWebGLStore((state) => state.compiled)
  const { lenis } = useLenis()

  useGSAP(
    (context) => {
      if (!lenis || !loaded || !compiled) return

      const revert = animation({
        node: root.current,
        mobile: isTouch,
        reverse: false,
        context,
        webgl: {
          image: image.current,
          shape: shape.current
        }
      })

      return () => {
        if (revert) {
          revert()
        }
      }
    },
    { dependencies: [lenis, loaded, compiled], scope: root, revertOnUpdate }
  )

  return children({
    root,
    image,
    shape
  })
}

export default MotionLanding
